<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Evaluaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Evaluaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">                      
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.evaluaciones.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th class="col-sm-2 col-md-2">
                        # Evaluación<input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup.enter="getIndex()"
                        />
                      </th>
                      <th>
                        Líneas Negocio
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="linea_negocio"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="listasForms.lineas_negocio"
                          @input="selectLinea()"
                        ></v-select>
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="centrar-th">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="eva in evaluaciones.data" :key="eva.id">
                      <td>
                        {{ eva.id }}
                      </td>
                      <td>{{ eva.nombre }}</td>
                      <td class="text-center">
                        <span class="badge bg-navy">
                          {{ eva.linea_negocio.nombre }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            eva.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ eva.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.evaluaciones.show')"
                            @click="edit(eva)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.evaluaciones.delete')"
                            @click="destroy(eva.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="evaluaciones.total">
                  <p>
                    Mostrando del <b>{{ evaluaciones.from }}</b> al
                    <b>{{ evaluaciones.to }}</b> de un total:
                    <b>{{ evaluaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="evaluaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import Loading from "../../../../components/Loading";

export default {
  name: "EvaluacionesIndex",
  components: {
    pagination,
    vSelect,
    Loading,    
  },
  data() {
    return {
      cargando: false,
      linea_negocio: [],
      evaluaciones: {},
      filtros: {
        id: null,
        nombre: null,
        descripcion: null,
        lineas_negocio: null,
        estado: null,
      },
      listasForms: {
        lineas_negocio: [],
        estados: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/admin/evaluaciones?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.evaluaciones = response.data;
          this.cargando = false;
        });
    },

    selectLinea() {
      this.filtros.lineas_negocio = [];
      if (this.linea_negocio) {
        this.linea_negocio.forEach((linea) => {
          this.filtros.lineas_negocio.push(linea.id);
        });
      }
      this.getIndex();
    },

    getLineasNegocio() {
      axios.get("/api/admin/lineasNegocios/lista").then((response) => {
        this.listasForms.lineas_negocio = response.data;
      });
    },

    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    destroy(idEmpresa) {
      this.$swal({
        title: "Esta seguro de eliminar esta Empresa?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/empresas/" + idEmpresa).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la Empresa exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/EvaluacionesForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(empresa) {
      return this.$router.push({
        name: "/Admin/EvaluacionesForm",
        params: { accion: "Editar", data_edit: empresa, id: empresa.id },
      });
    },

   /*  pivotTable() {
      return this.$router.push({
        name: "/Admin/EmpresaPivotTable",
      });
    }, */
  },

  mounted() {
    this.getIndex();
    this.getLineasNegocio();
    this.getEstados();
  },
};
</script>
<style>
.centrar-th {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 62px;
}
</style>
